import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import MainBanner from '../components/MainBanner'
import Sidebar from "../components/homeMenu/sideBar"
import Form from "../components/FormContacts"
import Contacts from '../components/Contacts'
import { toggleWhiteSidebar } from "../state/app"
import { InView } from 'react-intersection-observer'
import { connect } from 'react-redux'


const ContactoPage = ({ dispatch, location }) => {
    const { t } = useTranslation()

    var dataContact = t("contacto", { returnObjects: true })
    var dataGlobal = t("global", { returnObjects: true })

    return (
        <Layout
            data={dataGlobal}
            hideForm
            location={location}
        >
            <Seo title={dataContact.title} />
            <Sidebar
                content={dataGlobal.sidebar}
                projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
                projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
                whiteMobileNavbar
            />
            {/* CHANGE SIDE BAR COLOR TO WHITE */}
            <InView as="div" threshold={0.5} onChange={inView => dispatch(toggleWhiteSidebar(inView))}>
                <MainBanner data={dataContact.menu} />
            </InView>
            <Contacts data={dataContact.contact} />
            <Form data={dataContact.form} 
                msgContent={dataGlobal.msgMarkPhone}/>
        </Layout>
    )
}

export default connect()(ContactoPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["contacto", "global", "projetosVenda", "portfolio"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`