import React from "react"
import { media } from './Styles'
import styled from "styled-components"


import { handleCall } from "../utils/handleClick"

const Contacts = ({ data }) => {



    return (
        <StyledContacts>
            {data.map((contact, key) => (
                <div className='contact' key={key}>
                    <a href={contact.textLink} style={{ marginBottom: '1rem' }}><img src={contact.icon} alt='Icon' /></a>
                    <a href={contact.textLink}><h4 style={{ fontWeight: '600' }} dangerouslySetInnerHTML={{ __html: contact.text }}></h4></a>
                    {contact.text2Link.includes('tel:') ?
                        <a onClick={() => handleCall(contact.text2Link)}><h4 dangerouslySetInnerHTML={{ __html: contact.text2 }}></h4></a>
                        :
                        <a href={contact.text2Link}><h4 dangerouslySetInnerHTML={{ __html: contact.text2 }}></h4></a>
                    }
                    {contact.text3Link && contact.text3Link.includes('tel:') ?
                        <a onClick={() => handleCall(contact.text3Link)}><h4 dangerouslySetInnerHTML={{ __html: contact.text3 }}></h4></a>
                        :
                        <a href={contact.text3Link}><h4 dangerouslySetInnerHTML={{ __html: contact.text3 }}></h4></a>
                    }
                    {contact.text4Link && contact.text4Link.includes('tel:') ?
                        <a onClick={() => handleCall(contact.text4Link)}><h4 dangerouslySetInnerHTML={{ __html: contact.text4 }}></h4></a>
                        :
                        <a href={contact.text4Link}><h4 dangerouslySetInnerHTML={{ __html: contact.text4 }}></h4></a>
                    }
                </div>
            ))}
        </StyledContacts>
    )
}

export default Contacts

const StyledContacts = styled.div`
    margin: 0 0 0 7.3%;
    text-align:center;
    padding: clamp(50px,10vw,90px) 7.3%;
    display:flex;
    justify-content: space-between;
    align-items:center;
    gap:5%;

    a{
        cursor:pointer;
    }

    ${media.xl`
        flex-direction:column;
        gap:5rem;
    `}

    ${media.m`
        margin:0;
    `}

    .contact{
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        img{
            max-width:35px;
        }

        h4{
            font-weight:400;
            font-size:1.3rem;
        }
    }
`